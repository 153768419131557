import { useMemo } from 'react';
import Link from 'next/link';
import { DataSummaryVolume } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { ERoutePath } from '@/helpers/utils/constants';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { IGetChainsDataSummaryResponse } from '@/services/models/IGetChainsDataSummaryResponse';
import { getCategoryID } from './utils/getCategoryID';
import { getTokenExplorerLinkFilters } from './utils/getTokenExplorerLinkFilters';
import { IBSwiperItem, IBSwiperWrap } from './IBSwiper';
import { InfoBoxLink, InfoBoxWithCount, InfoBoxWithMoney, InfoBoxWrapper } from './InfoBox';
import swiperStyles from './IBSwiper.module.scss';

const normaliseData = (data: IGetChainsDataSummaryResponse['data'] | undefined) =>
  !data || data.length < 2 ? null : data.map((d) => Number(d.value));

const SWIPER_BREAKPOINTS = {
  0: {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 12,
  },
  768: {
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 12,
  },
  1400: {
    grabCursor: false,
    slidesPerView: 3,
    spaceBetween: 0,
  },
};

const INITIAL_SLIDE = 0;
const INITIAL_SLIDES_PER_VIEW = 4;

export const ChainsDataSummary = ({
  category,
  chainId,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  dataSummaryNetVolume,
}: {
  category: string | null;
  chainId: string | null;
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  dataSummaryNetVolume: DataSummaryVolume[];
}) => {
  const totalVolume = useMemo(() => normaliseData(dataSummaryTotalVolume), [dataSummaryTotalVolume]);
  //const netVolume = useMemo(() => normaliseData(dataSummaryNetVolume), [dataSummaryNetVolume]);

  const tokenExplorerLink =
    Number(dataSummaryTokens.tokensCount) > 0
      ? getTokenExplorerLinkFilters(chainId, category ? getCategoryID(category) : null)
      : ERoutePath.tokenExplorer;

  return (
    <InfoBoxWrapper>
      <IBSwiperWrap
        breakpoints={SWIPER_BREAKPOINTS}
        initialSlide={INITIAL_SLIDE}
        slidesPerView={INITIAL_SLIDES_PER_VIEW}
        customBodyClass={swiperStyles.ibSwiperWrapper}
        id="ChainsDataSummarySlider"
      >
        <IBSwiperItem>
          <InfoBoxWithCount title="Total Tokens" count={dataSummaryTokens.tokensCount}>
            {chainId !== null && (
              <Link href={tokenExplorerLink}>
                <InfoBoxLink>Token Explorer</InfoBoxLink>
              </Link>
            )}
          </InfoBoxWithCount>
        </IBSwiperItem>
        <IBSwiperItem>
          <InfoBoxWithCount title="New Coins 24H" count={dataSummaryTokens.oneDayTokensCount} />
        </IBSwiperItem>
        <IBSwiperItem>
          <InfoBoxWithMoney
            title="Total Volume 24H"
            money={dataSummaryTokens.oneDayVolumeUsd}
            graphData={totalVolume}
          />
        </IBSwiperItem>
      </IBSwiperWrap>
    </InfoBoxWrapper>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Badge, ButtonSecondary, Pagination } from 'moralis-ui';
import { CategoriesLinkList } from '@/components/common/HomePage/CategoriesLinkList';
import { ChainsLinkList } from '@/components/common/HomePage/HomePageChains';
import { HomePageTabs } from '@/components/common/HomePage/HomePageTabs';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { buildPath } from '@/components/HomePageTrending/utils/buildPath';
import { DataSummaryVolume } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { CHAIN_IDS } from '@/helpers/utils/constants';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { useDrawerStore } from '@/stores/drawerStore';
import { Advertisement, TGlossaryItem } from '@/utils/contentful/type';
import { TTokenStrategiesDataResponsePartial } from '@/utils/contentful/type';
import { SortOrder } from '@/utils/sorting';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortedCategoriesProps } from './utils/getSortedCategoriesByVolume';
import { createCategoryOptions, createChainIDOptions, OPTIONS_OF_TRENDING } from './utils/optionsSelector';
import { ChainsDataSummary } from './ChainsDataSummary';
import { TrendingPageUIEmpty } from './TrendingPageUIEmpty';
import { TrendingPageUIFilters } from './TrendingPageUIFilters';
import { TrendingTable } from './TrendingTable';
import { SortableColumnsTrending } from './TrendingToken';
import styles from './TrendingPageUI.module.scss';

const UNCATEGORIZED_CATEGORY_POSITION = 5;
const EXCLUDED_CHAIN_IDS = [CHAIN_IDS.cronos];

export const TrendingPageUI = ({
  currentPage,
  itemsPerPage,
  totalPages,
  trendingTokenPage,
  onChangePageNumber,
  tabId,
  onSortChange,
  sortingColumn,
  sortingOrder,
  chainId,
  category,
  onChangeCategory,
  onChangeChainId,
  selectedFiltersCount,
  glossaryMoneyMetricTerms,
  showTokenRank,
  categoryTitle,
  chainTitle,
  contentfulTokenDataStrategies,
  ads,
  sortedCategories,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  dataSummaryNetVolume,
}: {
  currentPage: number;
  itemsPerPage: number;
  onChangePageNumber: (newPage: number) => void;
  tabId: EHomePageView;
  totalPages: number;
  trendingTokenPage: TrendingCoinInfoDto[];
  onSortChange: (column: SortableColumnsTrending) => void;
  sortingColumn: SortableColumnsTrending;
  sortingOrder: SortOrder;
  chainId: string | null;
  category: string | null;
  onChangeChainId: (chainId: string | null) => void;
  onChangeCategory: (category: string | null) => void;
  selectedFiltersCount: number;
  glossaryMoneyMetricTerms: TGlossaryItem[];
  showTokenRank: boolean;
  categoryTitle?: string | undefined;
  chainTitle?: string | undefined;
  contentfulTokenDataStrategies?: TTokenStrategiesDataResponsePartial[];
  ads: Advertisement[];
  sortedCategories: SortedCategoriesProps;
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  dataSummaryNetVolume: DataSummaryVolume[];
}) => {
  const { showDrawer, hideDrawer, setDrawerContent } = useDrawerStore();

  const opt = {
    allCategories: createCategoryOptions(sortedCategories),
    category: category ?? OPTIONS_OF_TRENDING.defaultCategory,
    changeCategory: useCallback((cat: string) => {
      onChangeCategory(cat === OPTIONS_OF_TRENDING.defaultCategory ? null : cat);
      hideDrawer();
    }, []),

    allChainIds: createChainIDOptions(
      Object.values(CHAIN_IDS).filter((chainId) => !EXCLUDED_CHAIN_IDS.includes(chainId)),
    ),
    chainId: chainId ?? OPTIONS_OF_TRENDING.defaultChainId,
    changeChainId: useCallback((id: string) => {
      onChangeChainId(id === OPTIONS_OF_TRENDING.defaultChainId ? null : id);
      hideDrawer();
    }, []),
  };

  // Place the "Uncategorized" category first in the dropdown list
  const sortedCategoriesUncategorized = useMemo(() => {
    const categories = [...opt.allCategories];
    const uncategorizedCategory = categories.find((category) => category.id === OPTIONS_OF_TRENDING.uncategorized);
    if (uncategorizedCategory) {
      categories.splice(categories.indexOf(uncategorizedCategory), 1);
      categories.splice(UNCATEGORIZED_CATEGORY_POSITION, 0, uncategorizedCategory);
    }
    return categories;
  }, [opt.allCategories]);

  useEffect(() => {
    setDrawerContent(
      <TrendingPageUIFilters
        categoryOptions={opt.allCategories}
        onChangeCategory={opt.changeCategory}
        category={opt.category}
        chainIdOptions={opt.allChainIds}
        onChangeChainId={opt.changeChainId}
        chainId={opt.chainId}
        onCloseFilters={hideDrawer}
      />,
    );
  }, [setDrawerContent, onChangeCategory, onChangeChainId, category, chainId, hideDrawer]);

  const generateTrendingHref = useCallback(
    (chainId: string) => {
      return buildPath({ chainId, category: opt.category });
    },
    [opt.category],
  );

  return (
    <>
      <div className={styles.mainPage}>
        <ChainsLinkList
          options={opt.allChainIds}
          value={opt.chainId}
          onChainChange={opt.changeChainId}
          generateHref={generateTrendingHref}
        />

        {category !== OPTIONS_OF_TRENDING.uncategorized && (
          <ChainsDataSummary
            dataSummaryTokens={dataSummaryTokens}
            dataSummaryTotalVolume={dataSummaryTotalVolume}
            dataSummaryNetVolume={dataSummaryNetVolume}
            chainId={chainId}
            category={category === OPTIONS_OF_TRENDING.defaultCategory ? null : category}
          />
        )}

        <div className={styles.header}>
          <HomePageTabs tabId={tabId} />

          <CategoriesLinkList
            options={sortedCategoriesUncategorized}
            value={opt.chainId}
            currentCategory={opt.category}
            onChangeCategory={(categoryId) => opt.changeCategory(categoryId)}
          />
        </div>
        {trendingTokenPage.length > 0 ? (
          <div className={styles.pageInfoTable}>
            <div className={styles.tokensTable}>
              <TrendingTable
                ads={ads}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                rows={trendingTokenPage}
                sortingColumn={sortingColumn}
                sortingOrder={sortingOrder}
                onSortChange={onSortChange}
                glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
                showTokenRank={showTokenRank}
              />
            </div>

            {trendingTokenPage.length > 0 && totalPages > 1 && (
              <div className={styles.mainPagination}>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  key={`pagination-${currentPage}`}
                  totalItems={totalPages * itemsPerPage}
                  onPageChange={onChangePageNumber}
                />
              </div>
            )}
          </div>
        ) : (
          <TrendingPageUIEmpty
            onShowFilters={showDrawer}
            categoryTitle={categoryTitle}
            chainTitle={chainTitle}
            contentfulTokenDataStrategies={contentfulTokenDataStrategies}
            chainId={chainId}
            category={category === OPTIONS_OF_TRENDING.defaultCategory ? null : category}
            tokensCount={dataSummaryTokens.tokensCount}
          />
        )}
      </div>

      <div
        className={clsx(
          styles.tokenTableFiltersButton,
          selectedFiltersCount > 0 && styles.tokenTableFiltersButtonValues,
        )}
      >
        <ButtonSecondary aria-label="tableFilters" size="md" onClick={showDrawer}>
          <FontAwesomeIcon icon={faSliders} />
          Filters
          {selectedFiltersCount > 0 && (
            <span className={styles.tokenTableFiltersCount}>
              <Badge color="navy" id="tokenTableFiltersCount" size="md" text={selectedFiltersCount} />
            </span>
          )}
        </ButtonSecondary>
      </div>
    </>
  );
};

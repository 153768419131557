import dynamic from 'next/dynamic';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { DataSummaryVolume } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { useScrollToElement } from '@/helpers/hooks/useScrollToElement';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { Advertisement, TGlossaryItem } from '@/utils/contentful/type';
import { TTokenStrategiesDataResponsePartial } from '@/utils/contentful/type';
import { SortedCategoriesProps } from './utils/getSortedCategoriesByVolume';
import { TrendingPageUI } from './TrendingPageUI';
import { useTrending } from './useTrending';

const HomeBannerAds = dynamic(
  () => import('@/components/ads/homeBanners/HomeBannerAds').then((mod) => mod.HomeBannerAds),
  { ssr: false },
);

export const TrendingPage = ({
  trendingTokenData,
  chainId,
  category,
  glossaryMoneyMetricTerms,
  categoryTitle,
  chainTitle,
  contentfulTokenDataStrategies,
  ads,
  sortedCategories,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  dataSummaryNetVolume,
}: {
  trendingTokenData: TrendingCoinInfoDto[];
  chainId: string | null;
  category: string | null;
  glossaryMoneyMetricTerms: TGlossaryItem[];
  categoryTitle?: string | undefined;
  chainTitle?: string | undefined;
  contentfulTokenDataStrategies?: TTokenStrategiesDataResponsePartial[];
  ads: Advertisement[];
  sortedCategories: SortedCategoriesProps;
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  dataSummaryNetVolume: DataSummaryVolume[];
}) => {
  const { elementRef, scrollToElement } = useScrollToElement(-70);
  const { currentPageItems, vars, updateCurrentPageIndex, toggleSorting, updateCategory, updateChainId } = useTrending(
    trendingTokenData,
    {
      defaultChainId: chainId,
      defaultCategory: category,
    },
  );

  return (
    <div ref={elementRef}>
      {ads && <HomeBannerAds ads={ads} />}

      <TrendingPageUI
        ads={ads}
        currentPage={vars.currentIndex + 1}
        itemsPerPage={vars.itemsPerPage}
        tabId={EHomePageView.TRENDING}
        totalPages={vars.pagesLength}
        trendingTokenPage={currentPageItems}
        onChangePageNumber={(newPage: number) => {
          updateCurrentPageIndex(newPage - 1);
          setTimeout(scrollToElement, 10);
        }}
        onSortChange={toggleSorting}
        sortingColumn={vars.sortingColumn}
        sortingOrder={vars.sortingOrder}
        showTokenRank={vars.showTokenRank}
        //
        category={vars.category}
        onChangeCategory={(category) => {
          updateCategory(category);
          setTimeout(scrollToElement, 10);
        }}
        //
        chainId={vars.chainId}
        onChangeChainId={(chainId) => {
          updateChainId(chainId);
          setTimeout(scrollToElement, 10);
        }}
        selectedFiltersCount={vars.filtersCount}
        //
        glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
        categoryTitle={categoryTitle}
        chainTitle={chainTitle}
        contentfulTokenDataStrategies={contentfulTokenDataStrategies}
        sortedCategories={sortedCategories}
        dataSummaryTokens={dataSummaryTokens}
        dataSummaryTotalVolume={dataSummaryTotalVolume}
        dataSummaryNetVolume={dataSummaryNetVolume}
      />
    </div>
  );
};

import { useEffect, useMemo } from 'react';
import { useDefaultNavigator } from '@/helpers/hooks/useDefaultNavigator';
import { useTableDataStateURL } from '@/helpers/hooks/useTableDataStateURL';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { logApp } from '@/utils/logApp';
import { slicePage } from '@/utils/pagination';
import { sortAsNumbers } from '@/utils/sorting';
import { SortOrder } from '@/utils/sorting';
import { fromQueryToTrendingColumnName, getSortableTrendingTokenValue } from './TrendingToken';
import { SortableColumnsTrending } from './TrendingToken';
import { DefaultIDs, useTrendingFilters } from './useTrendingFilters';

const log = logApp.create('useTrending');

const ITEMS_PER_PAGE = 20;
export const useTrending = (
  trendingTokenData: TrendingCoinInfoDto[],
  { defaultChainId, defaultCategory }: DefaultIDs,
) => {
  const { pathname, navigate } = useDefaultNavigator();
  const filters = useTrendingFilters({
    defaultChainId,
    defaultCategory,
  });

  const tds = useTableDataStateURL({
    fromQuery: fromQueryToTrendingColumnName,
    totalLength: trendingTokenData.length,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const sortedData = useMemo(
    () =>
      sortAsNumbers(
        [...trendingTokenData],
        (it) => getSortableTrendingTokenValue(tds.tableSort.column, it),
        tds.tableSort.order,
      ),

    [trendingTokenData, tds.tableSort.column, tds.tableSort.order],
  );

  useEffect(() => {
    log.debug('useEffect', { fromFilter: filters.currentPath, pathname });
    if (filters.currentPath === pathname) {
      return;
    }
    navigate({
      pathname: filters.currentPath,
      searchString: tds.updateCurrentPageIndex(0),
    });
  }, [filters.currentPath, pathname]);

  const toggleSorting: (...a: Parameters<typeof tds.toggleSorting>) => void = (col) => {
    navigate({
      pathname: filters.currentPath,
      searchString: tds.toggleSorting(col),
    });
  };

  const showTokenRank = useMemo(() => {
    const isDefaultSorting =
      tds.tableSort.column === SortableColumnsTrending.hypeScore && tds.tableSort.order === SortOrder.DESC;

    return !filters.category && !filters.chainId && isDefaultSorting;
  }, [filters.category, filters.chainId, tds.tableSort.column, tds.tableSort.order]);

  return {
    currentPageItems: slicePage(tds.tablePage.itemsPerPage, tds.tablePage.currentIndex, sortedData),
    vars: {
      filtersCount: filters.filtersCount,
      chainId: filters.chainId,
      category: filters.category,
      pageIndex: tds.tablePage.currentIndex,
      sortingColumn: tds.tableSort.column,
      sortingOrder: tds.tableSort.order,
      showTokenRank,
      ...tds.tablePage,
    },
    updateCategory: filters.updateCategory,
    updateChainId: filters.updateChainId,
    updateCurrentPageIndex: (n: number) => {
      navigate({
        pathname: filters.currentPath,
        searchString: tds.updateCurrentPageIndex(n),
      });
    },
    toggleSorting,
  };
};

import clsx from 'clsx';
import { Table, TableCell, Tooltip } from 'moralis-ui';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { Advertisement, TGlossaryItem } from '@/utils/contentful/type';
import { SortOrder } from '@/utils/sorting';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableSortIcon } from '../common/TableSortIcon';
import { TrendingPageUITableBody } from './TrendingPageUITableBody';
import { SortableColumnsTrending } from './TrendingToken';
import styles from './TrendingPageUI.module.scss';
import tableTokenStyle from './TrendingPageUITableBody.module.scss';

const renderTooltip = (description: string) =>
  description && (
    <span className={tableTokenStyle.tokenTableHeaderTooltip}>
      <Tooltip
        // Quick temporary fix added. TODO: Remove when the new stable layout is implemented.
        style={{ zIndex: '30' }}
        color="navy"
        content={description}
        position="top"
        size="md"
        trigger={<FontAwesomeIcon icon={faCircleInfo} width={12} />}
      />
    </span>
  );

export const TrendingTable = ({
  currentPage,
  itemsPerPage,
  rows,
  sortingColumn,
  sortingOrder,
  onSortChange,
  glossaryMoneyMetricTerms,
  showTokenRank,
  ads,
}: {
  currentPage: number;
  itemsPerPage: number;
  rows: TrendingCoinInfoDto[];
  sortingColumn: SortableColumnsTrending;
  sortingOrder: SortOrder;
  onSortChange: (column: SortableColumnsTrending) => void;
  glossaryMoneyMetricTerms: TGlossaryItem[];
  showTokenRank: boolean;
  ads: Advertisement[];
}) => {
  const getDescriptionByItem = (itemName: string) => {
    const term = glossaryMoneyMetricTerms.find((term) => term.item?.toLowerCase() === itemName.toLowerCase());
    return term?.description || '';
  };

  const renderSortableCell = (label: string, column: SortableColumnsTrending, itemName?: string) => {
    const description = itemName ? getDescriptionByItem(itemName) : '';

    return (
      <TableCell align="left" aria-label={column} variant="headerCell">
        <span className={styles.tokenTableHeader}>
          {renderTooltip(description)}
          <span
            className={clsx('caption-14-medium', styles.tokenTableHeaderFilters)}
            onClick={() => onSortChange(column)}
          >
            {label}
            <TableSortIcon isSelected={column === sortingColumn} isAsc={sortingOrder === SortOrder.ASC} />
          </span>
        </span>
      </TableCell>
    );
  };

  const renderNoSortableCell = (label: string, itemName?: string) => {
    const description = itemName ? getDescriptionByItem(itemName) : '';

    return (
      <TableCell align="left" aria-label={label} variant="headerCell">
        <span className={styles.tokenTableHeader}>
          {renderTooltip(description)}
          <span className={clsx('caption-14-medium', styles.tokenTableHeaderFilters)}>{label}</span>
        </span>
      </TableCell>
    );
  };

  return (
    <Table
      transparent
      className={styles.mainPageTable}
      hasBorder={false}
      size="md"
      tbodyCells={
        <TrendingPageUITableBody
          ads={ads}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          trendingTokenPage={rows}
          showTokenRank={showTokenRank}
        />
      }
      theadCells={
        <tr>
          <TableCell align="left" aria-label="tokenInfo" className={tableTokenStyle.fieldToken} variant="headerCell">
            <span className={clsx('caption-14-medium', styles.tokenTableHeader)}># Token</span>
          </TableCell>
          {showTokenRank && (
            <TableCell
              align="left"
              aria-label="tokenInfoRank"
              className={tableTokenStyle.fieldTokenRank}
              variant="headerCell"
            ></TableCell>
          )}
          {renderSortableCell('Hype Score', SortableColumnsTrending.hypeScore, 'Hype Score')}
          {renderSortableCell('Max ROI', SortableColumnsTrending.roi, 'ROI')}
          {renderSortableCell('Price (24H)', SortableColumnsTrending.price24HChange, 'Price Percent Change')}
          {renderSortableCell('FDV', SortableColumnsTrending.fullyDilutedValuation, 'Fully Diluted Valuation')}
          {renderSortableCell('Coin Age', SortableColumnsTrending.coinAge, 'Coin Age')}
          {renderSortableCell('Vol (24H)', SortableColumnsTrending.volume24H, 'Total Volume')}
          {renderSortableCell('Buy Pressure (24H)', SortableColumnsTrending.buyPressure, 'Buy Pressure')}
          {renderSortableCell('Followers', SortableColumnsTrending.twitterFollowerCount, 'Social Media Metrics')}
          {renderNoSortableCell('Categories', 'Category')}
          {renderSortableCell('Security', SortableColumnsTrending.moralisScore, 'Security Score')}
        </tr>
      }
    />
  );
};
